<template>
    <div class="container">
        <el-row>
            <content-title :nav="nav"></content-title>
        </el-row>
        <el-descriptions title="订单信息" border>
            <el-descriptions-item label="商品名称">
                <p class="link" @click="toProducDetails">
                    {{ getTitle() }}
                </p>
            </el-descriptions-item>
            <el-descriptions-item label="购买数量">{{ orderInfo.course_num }}</el-descriptions-item>
            <el-descriptions-item label="订单类型">{{ orderType(orderInfo.rcharge_type) }}</el-descriptions-item>
            <el-descriptions-item label="下单来源">{{ source[orderInfo.source] }}</el-descriptions-item>
            <el-descriptions-item label="支付方式">{{ pay_type[orderInfo.pay_type] }}</el-descriptions-item>
            <el-descriptions-item label="应付金额">￥{{ orderInfo.amount }}</el-descriptions-item>
            <el-descriptions-item label="补录金额" v-if="orderInfo.offline_collection > 0">￥{{ orderInfo.offline_collection
            }}</el-descriptions-item>
            <el-descriptions-item label="使用消费金">{{ orderInfo.consume }}</el-descriptions-item>
            <el-descriptions-item label="订单号">{{ orderInfo.flowing_no }}</el-descriptions-item>
            <el-descriptions-item label="下单时间">{{ orderInfo.rcharge_time }}</el-descriptions-item>
            <el-descriptions-item label="支付时间">{{ orderInfo.finished_time }}</el-descriptions-item>
            <el-descriptions-item label="机构名称">{{ orderInfo.map.mechanismEntity ? orderInfo.map.mechanismEntity.mechanism_name : ''
            }}</el-descriptions-item>
            <el-descriptions-item label="下单用户">
                <link-to :to="{name:'userDetails',params:{id:orderInfo.user_id}}"> {{ orderInfo.map.userinfo.nick_name }}</link-to>
               </el-descriptions-item>
            <el-descriptions-item label="用户电话">{{ orderInfo.map.userinfo.mobile }}</el-descriptions-item>
            <el-descriptions-item label="订单状态">
                <p :style="{ color: getStatus().color }">{{ getStatus().name }}</p>
            </el-descriptions-item>
            <el-descriptions-item label="售后详情" v-if="orderInfo.reverse_status!=0">
               <link-to :to="{name:'refundDetails',query:{oid:orderInfo.id}}">查看详情</link-to>
            </el-descriptions-item>
            <el-descriptions-item label="补录图片"  v-if="orderInfo.offline_collection_img">
                <el-image
                style="width: 150px; height: 150px"
                :src="orderInfo.offline_collection_img"
                :fit="cover"
                :preview-src-list="[orderInfo.offline_collection_img]"
              ></el-image>
            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import { getorderType } from "@/utils/map/orderType.js"
import { goodsDetails } from "@/utils/orderUtils.js"


export default {

    data() {
        return {
            nav: { firstNav: "订单管理", secondNav: "订单详情" },
            id: "",
            orderInfo: {},
            source: {
                sport: "微信小程序",
                dy_sport: "抖音小程序",
                android: "安卓APP",
                ios: "ios APP",
                pc: "pc"
            },
            pay_type: {
                ali: '支付宝',
                wx: "微信",
                dy: "抖音支付",
                point: "权益点",
            },
            statusList: {
                '0': { name: '待付款', color: '#fb3a13' },
                '1': { name: '待付款', color: '#fb3a13' },
                '2': { name: '已付款', color: 'green' },
                '3': { name: '已退款', color: '#f0ad4e' },
                '10': { name: '待退款', color: '#333' },
                '8': { name: '已取消', color: '#999' },
                '5': { name: '已取消', color: '#999' },
            },
            reverse_statusList: {
					'1': {name:'退款中',color:'#f0ad4e'},
					'2': {name:'换课中',color:'#f0ad4e'},
					'3': {name:'已退款',color:'#FC7819'},
					'4': {name:'拒绝退款',color:'#fb3a13'},
					'5': {name:'已换课',color:'#FC7819'},
					'6': {name:'拒绝换课',color:'#fb3a13'},
					'7': {name:'取消退款',color:'#999'},
					'8': {name:'取消换课',color:'#999'},
				},
        };
    },

    mounted() {
        this.id = this.$route.params.id
        this.getOrderInfo()
    },

    methods: {
        getTitle(){
            if(this.orderInfo.title){
                return this.orderInfo.title
            }else if(this.orderInfo.map.masterSetPriceEntity){
                return this.orderInfo.map.masterSetPriceEntity.title
            }
            return ""
        },
        toProducDetails(){
            goodsDetails(this.orderInfo)
        },
        getStatus() {
            let { status, reverse_status } = this.orderInfo
            if (reverse_status != 0) {
                return this.reverse_statusList[reverse_status]
            } else {
                return this.statusList[status]
            }
        },
        orderType(type) {
            return getorderType(type).label
        },
        getOrderInfo() {
            this.$axios({
                url: "user/rechargerecord/queryByMessage",
                params: {
                    id: this.id
                }
            }).then(res => {
                this.orderInfo = res.data.data.rows[0]
            })
        }
    },
};
</script>

<style lang="less" scoped></style>